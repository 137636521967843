import React from 'react'
import PropTypes from 'prop-types'

import ReactSidebar from 'react-sidebar'

import Scrollbar from 'components/common/Scrollbar'

import Button from './Button'

export class Sidebar extends React.Component {
  static propTypes = {
    sidebar: PropTypes.element,
    children: PropTypes.object.isRequired
  }

  state = {
    open: true
  }

  handleButtonClick = () => {
    this.setState({open: !this.state.open})
  }

  render () {
    const {open} = this.state
    const {sidebar, children, ...props} = this.props

    const styles = {
      sidebar: {
        width: 320,
        zIndex: 1,
        overflowY: 'visible',
        paddingTop: 107,
        background: '#fbfafc',
        borderRight: '1px solid #e5e5e5'
      },
      content: {
        marginTop: 107
      }
    }

    return (
      <ReactSidebar
        {...props}
        open={sidebar && open}
        styles={styles}
        shadow={false}
        docked={sidebar && open}
        sidebar={sidebar
          ? (
            <Scrollbar>
              <div>
                {sidebar}
                <Button
                  active={open}
                  onClick={this.handleButtonClick}
                />
              </div>
            </Scrollbar>
          )
          : <div />
        }
        transitions={false}
        touchHandleWidth={0}
      >
        {children}
      </ReactSidebar>
    )
  }
}

export default Sidebar
