import React from 'react'
import PropTypes from 'prop-types'

import {colors} from 'utils/styles'

import classes from './Select.module.css'

class Select extends React.Component {
  static propTypes = {
    icon: PropTypes.func,
    arrow: PropTypes.bool,
    value: PropTypes.string,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
  }

  handleChange = ({target}) => {
    const {options, onChange} = this.props

    onChange(options[target.selectedIndex])
  }

  render () {
    const {icon: Icon, arrow, value, options} = this.props

    const style = arrow ? {} : {
      background: 'none',
      paddingRight: 0
    }

    return (
      <span className={classes.container}>
        {Icon && (
          <span>
            <Icon className={classes.icon} />
          </span>
        )}
        <select
          style={style}
          value={value}
          onChange={this.handleChange}
          className={classes.dropdown}
        >
          {
            options.map(({value, label}) => (
              <option
                key={value}
                value={value}
                style={{color: colors.text}}
              >
                {label}
              </option>
            ))
          }
        </select>
      </span>
    )
  }
}

export default Select
