import React from 'react'
import PropTypes from 'prop-types'

import {navigate} from 'gatsby'

import {MdApps} from 'react-icons/md'
import {withNamespaces} from 'react-i18next'

import Select from 'components/common/Select'

class ProductMenu extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    t: PropTypes.func.isRequired,
    lng: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  }

  handleChange = ({href, value}) => {
    navigate(href)
    this.props.onChange(value)
  }

  componentDidMount () {
    const {onChange, location} = this.props

    const parts = location.pathname.split('/')
    const product = parts[2]

    if (location.state && location.state.product) {
      onChange(location.state.product)
      return
    }

    if (product) {
      onChange(product)
      return
    }

    onChange('products')
  }

  render () {
    const {t, lng, value} = this.props

    return (
      <Select
        arrow
        icon={MdApps}
        value={value}
        options={[
          {value: 'products', label: t('Layout.ProductMenu.products'), href: `${lng}/products`},
          {value: 'fleerp', label: 'Fleerp', href: `${lng}/fleerp`},
          {value: 'politis', label: 'Politis', href: `${lng}/politis/docs`}
        ]}
        onChange={this.handleChange}
      />
    )
  }
}

export default withNamespaces()(ProductMenu)
