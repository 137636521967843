import React from 'react'
import PropTypes from 'prop-types'
import {graphql, StaticQuery} from 'gatsby'

import Carousel from 'components/common/Carousel'
import {ExtraLarge} from 'components/common/Responsive'

import Item from './Item'

export const Reviews = ({data, show}) => {
  const items = data.allFile.edges[0].node.childrenReviewsJson

  if (!show) {
    return null
  }

  return (
    <ExtraLarge>
      {matches => (
        <Carousel
          autoPlay
          infiniteLoop
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          centerMode={matches}
          centerSlidePercentage={matches ? 33 : 80}
        >
          {
            items.map((item, i) => (
              <Item key={i} item={item} />
            ))
          }
        </Carousel>
      )}
    </ExtraLarge>
  )
}

Reviews.propTypes = {
  show: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired
}

const ReviewsQuery = props => (
  <StaticQuery
    query={graphql`
      {
        allFile(filter: {extension: {eq: "json"} relativeDirectory: {eq: "reviews"}}) {
          edges {
            node {
              childrenReviewsJson {
                name
                rating
                comment
              }
            }
          }
        }
      }`
    }

    render={data => <Reviews {...props} data={data} />}
  />
)

export default ReviewsQuery
