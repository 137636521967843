import React from 'react'

import Link from 'components/common/Link'
import {colors} from 'utils/styles'

const Logo = () => (
  <Link
    to=''
    style={{
      color: colors.brandDark,
      fontSize: 20,
      fontWeight: 700,
      textTransform: 'uppercase'
    }}
  >
    Clouway
  </Link>
)

export default Logo
