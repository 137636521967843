import React from 'react'
import PropTypes from 'prop-types'

import Link from 'gatsby-link'
import {withNamespaces} from 'react-i18next'

const LinkWrapper = ({t, to, tReady, i18n, i18nOptions, defaultNS, reportNS, ...props}) => (
  <Link to={`/${i18n.language}${to}`} {...props} />
)

LinkWrapper.propTypes = {
  t: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,
  tReady: PropTypes.bool.isRequired,
  i18nOptions: PropTypes.object.isRequired,
  reportNS: PropTypes.string,
  defaultNS: PropTypes.string
}

export default withNamespaces()(LinkWrapper)
