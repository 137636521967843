export const colors = {
  brandDarker: '#221133',
  brandDark: '#442266',
  brand: '#663399',
  brandBright: '#e0d6eb',
  brandLight: '#f5f3f7',
  brandLighter: '#fbfafc',
  lightest: '#ffffff',
  darkest: '#4d4058',
  textLight: '#7e718a',
  textLighter: '#aaaaaa',
  text: '#333333',
  lilac: '#8c65b3',
  accent: '#ffb238'
}
