import React from 'react'
import PropTypes from 'prop-types'

import {withNamespaces} from 'react-i18next'
import {FaChevronRight} from 'react-icons/fa'

import Link from 'components/common/Link'
import {colors} from 'utils/styles'

export const Breadcrumb = ({t, lng, location}) => {
  const parts = location.pathname.replace(`/${lng}`, '').split('/')
  const paths = Array.from(new Set(parts))

  const linkStyle = {
    color: colors.brand,
    display: 'inline',
    marginRight: 5
  }

  const breadcrumbStyle = {
    margin: 7,
    fontSize: 12,
    textTransform: 'uppercase'
  }

  const breadcrumb = {
    '': t('Layout.Breadcrumb.home'),
    'apps': t('Layout.Breadcrumb.apps'),
    'docs': t('Layout.Breadcrumb.docs'),
    'devices': t('Layout.Breadcrumb.devices')
  }

  return (
    <div style={breadcrumbStyle}>
      {
        paths.map((p, i) => {
          const path = paths.slice(0, i + 1).join('/')

          return (
            <Link key={i} to={path} style={linkStyle}>
              <FaChevronRight size={10} />
              {` ${breadcrumb[p] ? breadcrumb[p] : p.replace('_', ' ')}`}
            </Link>
          )
        })
      }
    </div>
  )
}

Breadcrumb.propTypes = {
  t: PropTypes.func.isRequired,
  lng: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
}

export default withNamespaces()(Breadcrumb)
