import React from 'react'
import PropTypes from 'prop-types'

import {Grid, Row, Col} from 'react-flexbox-grid'

import Breadcrumb from './Breadcrumb'

const Body = ({center = true, children, location, breadcrumb}) => (
  <div>
    {
      !center ? children : (
        <Grid>
          <Row>
            <Col xs={12}>
              {breadcrumb && <Breadcrumb location={location} />}
              {children}
            </Col>
          </Row>
        </Grid>
      )
    }
  </div>
)

Body.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node,
  breadcrumb: PropTypes.bool,
  location: PropTypes.object.isRequired
}

export default Body
