import React from 'react'
import PropTypes from 'prop-types'

import {Grid, Row, Col} from 'react-flexbox-grid'

import {colors} from 'utils/styles'

import Menu from './Menu'
import Logo from './Logo'
import ProductMenu from './ProductMenu'
import LanguageSwitcher from './LanguageSwitcher'

class Header extends React.Component {
  static propTypes = {
    fluid: PropTypes.bool,
    location: PropTypes.object.isRequired
  }

  state = {}

  handleProductChange = product => {
    this.setState({product})
  }

  render () {
    const {fluid, location} = this.props
    const {product} = this.state

    return (
      <div style={{width: '100%', zIndex: 2, position: 'fixed'}}>
        <div style={{background: colors.brand}}>
          <Grid fluid={fluid}>
            <Row end='xs' middle='xs' style={{height: 53}}>
              <Col xs>
                <LanguageSwitcher />
                <ProductMenu
                  value={product}
                  location={location}
                  onChange={this.handleProductChange}
                />
              </Col>
            </Row>
          </Grid>
        </div>
        <div style={{background: '#ffffff', borderBottom: '1px solid #0000001f'}}>
          <Grid fluid={fluid}>
            <Row middle='xs' between='xs' style={{height: 53}}>
              <Col xs={5} sm={3}>
                <Logo />
              </Col>
              <Col xs={7} sm={9}>
                <Row end='xs'>
                  <Col>
                    <Menu product={product} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    )
  }
}

export default Header
