import React from 'react'
import PropTypes from 'prop-types'

import Helmet from 'react-helmet'
import {withNamespaces} from 'react-i18next'

import Reviews from 'components/Reviews'
import Scrollbar from 'components/common/Scrollbar'

import I18N from './I18N'
import Body from './Body'
import Header from './Header'
import Sidebar from './Sidebar'

import './Layout.css'

class Layout extends React.Component {
  static propTypes = {
    fluid: PropTypes.bool,
    center: PropTypes.bool,
    sidebar: PropTypes.node,
    children: PropTypes.node,
    breadcrumb: PropTypes.bool,
    onI18NInitialized: PropTypes.func,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  }

  componentDidMount () {
    const {i18n, onI18NInitialized} = this.props

    if (onI18NInitialized) {
      onI18NInitialized(i18n)
    }
  }

  render () {
    const {t, fluid, center, sidebar, children, navigate, location, breadcrumb} = this.props

    return (
      <I18N navigate={navigate} location={location}>
        <React.Fragment>
          <Helmet
            title={t('Layout.meta.title')}
            meta={[
              {
                name: 'description',
                content: t('Layout.meta.description')
              },
              {
                name: 'keywords',
                content: t('Layout.meta.keywords')
              },
              {
                name: 'robots',
                content: 'index, follow'
              }
            ]}
          >
            <html lang='en' />
          </Helmet>
          <Header fluid={fluid} location={location} />
          <Sidebar sidebar={sidebar}>
            <Scrollbar>
              <Body center={center} location={location} breadcrumb={breadcrumb}>
                {children}
              </Body>
              <Reviews show={!sidebar} />
            </Scrollbar>
          </Sidebar>
        </React.Fragment>
      </I18N>
    )
  }
}

export default withNamespaces()(Layout)
