import React from 'react'
import PropTypes from 'prop-types'

import Scrollbar from 'react-scrollbars-custom'

export class ScrollbarWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  }

  scrollbar = React.createRef()

  showTracks = () => {
    this.scrollbar.current.trackXEl.style.opacity = 1
    this.scrollbar.current.trackYEl.style.opacity = 1

    this.dragEndTO && clearTimeout(this.dragEndTO)
    this.dragEndTO = null
  }

  hideTracks = () => {
    if (
      !this.scrollbar.current ||
      this.scrollbar.current.thumbXEl.classList.contains('dragging') ||
      this.scrollbar.current.thumbYEl.classList.contains('dragging')
    ) {
      return
    }

    this.scrollbar.current.trackXEl.style.opacity = 0
    this.scrollbar.current.trackYEl.style.opacity = 0

    this.dragEndTO && clearTimeout(this.dragEndTO)
    this.dragEndTO = null
  }

  handleDragEnd = () => {
    this.dragEndTO = setTimeout(this.hideTracks, 1000)
  }

  render () {
    const {children} = this.props

    return (
      <Scrollbar
        removeTracksWhenNotUsed={false}
        trackYProps={{style: {opacity: 0}, onMouseOver: this.showTracks, onMouseOut: this.hideTracks}}
        trackXProps={{style: {opacity: 0}, onMouseOver: this.showTracks, onMouseOut: this.hideTracks}}
        thumbXProps={{onDragEnd: this.handleDragEnd}}
        thumbYProps={{onDragEnd: this.handleDragEnd}}
        ref={this.scrollbar}
        onScrollStart={this.showTracks}
        onScrollStop={this.hideTracks}
        scrollDetectionThreshold={1000}
        style={{height: '100%'}}
      >
        {children}
      </Scrollbar>
    )
  }
}

export default ScrollbarWrapper
