/**
 * @author Grisha Angelov <grisha.angelov@clouway.com>
 */

import React from 'react'

export const withGoogleAnalytics = (Component) => {
  return class extends React.Component {
    sendAnalyticsEvent (e) {
      if (window.ga) {
        window.ga('send', 'event', e)
      }
    }

    render () {
      return <Component {...this.props} sendAnalyticsEvent={this.sendAnalyticsEvent} />
    }
  }
}

export default withGoogleAnalytics
