import React from 'react'
import PropTypes from 'prop-types'

import {MdChevronLeft, MdChevronRight} from 'react-icons/md'

import classes from './Button.module.css'

const SideButton = ({active, onClick}) => {
  if (active) {
    return (
      <MdChevronLeft
        size={35}
        onClick={onClick}
        className={classes.active}
      />
    )
  }

  return (
    <MdChevronRight
      size={35}
      onClick={onClick}
      className={classes.inactive}
    />
  )
}

SideButton.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default SideButton
