import React from 'react'

import Responsive from 'react-responsive'

export const Small = props => <Responsive {...props} minWidth={576} />
export const Medium = props => <Responsive {...props} minWidth={768} />
export const Large = props => <Responsive {...props} minWidth={992} />
export const ExtraLarge = props => <Responsive {...props} minWidth={1200} />

export default Responsive
