import React from 'react'
import PropTypes from 'prop-types'

import {MdLanguage} from 'react-icons/md'
import {withNamespaces} from 'react-i18next'
import {graphql, StaticQuery} from 'gatsby'

import Select from 'components/common/Select'
import withGoogleAnalytics from 'components/common/withGoogleAnalytics'

class LanguageSwitcher extends React.Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired,
    sendAnalyticsEvent: PropTypes.func.isRequired
  }

  static getDerivedStateFromProps ({i18n: {language}}) {
    return {language}
  }

  constructor (props) {
    super(props)

    this.state = {
      language: props.i18n.language
    }

    this.handleChangeLanguage = this.handleChangeLanguage.bind(this)
  }

  handleChangeLanguage ({value}) {
    this.props.i18n.changeLanguage(value)

    this.props.sendAnalyticsEvent({
      eventCategory: 'Language Switch',
      eventAction: value
    })
  }

  render () {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                languages {
                  code
                  label
                }
              }
            }
          }
        `}
        render={data => (
          <Select
            icon={MdLanguage}
            value={this.state.language}
            options={data.site.siteMetadata.languages.map(({code, label}) => ({value: code, label: label}))}
            onChange={this.handleChangeLanguage}
          />
        )}
      />
    )
  }
}

export default withGoogleAnalytics(withNamespaces()(LanguageSwitcher))
