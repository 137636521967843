import React from 'react'
import PropTypes from 'prop-types'

import ResponsiveMenu from 'react-responsive-navbar'
import {MdMenu, MdClose} from 'react-icons/md'

import {Medium} from 'components/common/Responsive'
import GatsbyLink from 'components/common/Link'

import {colors} from 'utils/styles'
import classes from './MenuItems.module.scss'

const Link = ({partial, ...props}) => (
  <Medium>
    {matches => (
      <GatsbyLink {...props} getProps={({isCurrent, isPartiallyCurrent}) => {
        if (isCurrent || (partial && isPartiallyCurrent)) {
          return {
            style: {
              ...props.style,
              color: colors.brand,
              borderBottom: matches ? `1px solid ${colors.brand}` : null
            }
          }
        }

        return null
      }} />
    )}
  </Medium>
)

Link.propTypes = {
  style: PropTypes.object,
  partial: PropTypes.bool.isRequired
}

const MenuItems = ({t, product, productCategories}) => {
  return (
    <ResponsiveMenu
      menuOpenButton={<MdMenu size={30} style={{margin: '5px 5px 0 0'}} />}
      menuCloseButton={<MdClose size={30} style={{margin: '5px 5px 0 0'}} />}
      changeMenuOn='767px'
      largeMenuClassName={classes.large}
      smallMenuClassName={classes.small}
      menu={
        productCategories.map(l => (
          <Link
            to={l.path}
            key={l.path}
            state={{product}}
            partial={l.partial}
          >
            {t(`Layout.MenuItems.${l.label}`)}
          </Link>
        ))
      }
    />
  )
}

MenuItems.propTypes = {
  t: PropTypes.func.isRequired,
  product: PropTypes.string,
  productCategories: PropTypes.array.isRequired
}

export default MenuItems
