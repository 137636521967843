import React from 'react'
import PropTypes from 'prop-types'

import {NamespacesConsumer} from 'react-i18next'

import MenuItems from './MenuItems'

const products = {
  fleerp: [
    {
      path: '/fleerp',
      label: 'features',
      partial: false
    },
    {
      path: '/fleerp/apps',
      label: 'apps',
      partial: true
    },
    {
      path: '/fleerp/devices',
      label: 'devices',
      partial: true
    },
    {
      path: '/fleerp/docs',
      label: 'docs',
      partial: true
    }
  ],
  politis: [
    {
      path: '/politis/docs',
      label: 'docs',
      partial: true
    }
  ]
}

const categories = [
  {
    path: '/products',
    label: 'products',
    partial: true
  },
  {
    path: '/contact',
    label: 'contact',
    partial: true
  }
]

const Menu = ({product}) => {
  let productCategories = []

  if (products[product]) {
    productCategories = [
      ...productCategories,
      ...products[product]
    ]
  }

  productCategories = [
    ...productCategories,
    ...categories
  ]

  return (
    <NamespacesConsumer>
      {
        t => (
          <div style={{fontSize: 15, fontWeight: 200, textTransform: 'uppercase'}}>
            <MenuItems t={t} product={product} productCategories={productCategories} />
          </div>
        )
      }
    </NamespacesConsumer>
  )
}

Menu.propTypes = {
  product: PropTypes.string
}

export default Menu
