import React from 'react'
import PropTypes from 'prop-types'

import {Col, Grid, Row} from 'react-flexbox-grid'

import Rating from 'components/common/Rating'

import avatar from './avatar.png'
import classes from './Reviews.module.scss'

export const Item = ({item}) => (
  <Grid className={classes.item}>
    <Row middle='xs' between='xs'>
      <Col md={6} className={classes.user}>
        <img src={avatar} style={{width: 35, margin: 20}} />
        <div className={classes.label}>{item.name} :</div>
      </Col>
      <Col md={4}>
        <Rating
          name='rating'
          value={item.rating}
          editing={false}
          starColor='#abbc15'
        />
      </Col>
    </Row>

    <Row middle='xs'>
      <Col>
        "{item.comment}"
      </Col>
    </Row>
  </Grid>
)

Item.propTypes = {
  item: PropTypes.object.isRequired
}

export default Item
